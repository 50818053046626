.stockMainPage{
    display: flex;
    height: 100vh;

    .stockContent {
        flex: 21;
        display: flex;
        flex-direction: column;

        .stockHeader{
            padding: 20px;
        }
        
        .stockTable{
            flex: 1;
        }
    }
}