.addStock{
    display: flex;
    height: 100vh;

    .addStockContent{
        flex: 21;
        display: flex;
        flex-direction: column;

        .addStockContentHeader{
            background-color: lightgreen;
        }

        .addStockForm {
            background-color: lightblue;
            padding: 20px;
            flex: 1;

            .grower,
            .product {
                display: flex;
            }
        }
    }
}